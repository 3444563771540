/*
Function to store base url endpoint of global apis
*/

export const baseUrl = (url) => {
  const checkLocation = location?.hostname;
  let apiUrl = "";
  if (checkLocation === "connexcel-uat.reyna-solutions.com") {
    apiUrl = `https://connexcel-uat-api.reyna-solutions.com/api/${url}`;
  } else if (checkLocation === "connexcel-qa.reyna-solutions.com") {
    apiUrl = `https://connexcel-qa-api.reyna-solutions.com/api/${url}`;
  } else if (checkLocation === "connexcel-staging.reyna-solutions.com") {
    apiUrl = `https://connexcel-staging-api.reyna-solutions.com/api/${url}`;
  } else if (checkLocation === "www.mytuitions.co.uk") {
    apiUrl = `https://connexcel-prod-api.mytuitions.co.uk/api/${url}`;
  } else {
    apiUrl = `https://connexcel-api.reyna-solutions.com/api/${url}`;
    // apiUrl = `https://connexcel-qa-api.reyna-solutions.com/api/${url}`;
    // apiUrl = `https://localhost:7046/api/${url}`;
    // apiUrl = `https://connexcel-be-dev.archesoftronix.in/api/${url}`;
    // apiUrl = `https://ws7xfhmh-5200.inc1.devtunnels.ms/api/${url}`;
  }
  return apiUrl;
};

export const secondaryBaseUrl = (url) => {
  const checkLocation = location?.hostname;
  let apiUrl = "";
  if (checkLocation === "connexcel-uat.reyna-solutions.com") {
    apiUrl = `https://connexcel-uat-api.reyna-solutions.com/${url}`;
  } else if (checkLocation === "connexcel-qa.reyna-solutions.com") {
    apiUrl = `https://connexcel-qa-api.reyna-solutions.com/${url}`;
  } else if (checkLocation === "connexcel-staging.reyna-solutions.com") {
    apiUrl = `https://connexcel-staging-api.reyna-solutions.com/${url}`;
  } else if (checkLocation === "www.mytuitions.co.uk") {
    apiUrl = `https://connexcel-prod-api.mytuitions.co.uk/${url}`;
  } else {
    apiUrl = `https://connexcel-api.reyna-solutions.com/${url}`;
    // apiUrl = `https://ws7xfhmh-5200.inc1.devtunnels.ms/${url}`;
    // apiUrl = `https://localhost:7046/${url}`;
  }
  return apiUrl;
};

/*
Add all api endpoints in this object and then access to your components
*/

export const api = {
  login: baseUrl("Auth/Login"),
  sendForgotPassword: baseUrl("Auth/SendForgotPasswordEmail"),
  resetPassword: baseUrl("Auth/ResetPassword"),
  resetPasswordVerifyLink: baseUrl("Auth/VerifyResetPasswordLink"),
  getAllUserDetailList: baseUrl("User/GetAllUserDetailList"),
  userTypeList: baseUrl("Common/GetUserTypeList"),
  createNewToken: baseUrl("Auth/CreateNewToken"),
  commonStatusList: baseUrl("Common/GetUserStatusList"),
  timeZoneList: baseUrl("Common/GetTimeZoneList"),
  ageGroupList: baseUrl("Common/GetAgeGroupList"),
  subjectList: baseUrl("Common/GetSubjectList"),
  courseTypeList: baseUrl("Common/GetCourseTypeList"),
  addEditUser: baseUrl("User/AddEditUser"),
  getUserById: baseUrl("User/GetUserDetailById"),
  changeUserStatus: baseUrl("User/ChangeUserStatusById"),
  userFullNameList: baseUrl("User/GetAllUserFullNameList"),
  getStudentDefaultRate: baseUrl(
    "Course/GetStudentDefaultRateByStdIdCrsTypeId"
  ),
  getTutorHourlyRate: baseUrl("Course/GetTutorDefaultRateByStdIdCrsTypeId"),
  addEditCourse: baseUrl("Course/AddEditCourse"),
  getAllCourseDetailList: baseUrl("Course/GetAllCourseDetailList"),
  changeCourseStatus: baseUrl("Course/ChangeCourseStatusById"),
  getCourseDetailById: baseUrl("Course/GetCourseDetailById"),
  getCurrencyList: baseUrl("Common/GetCurrencyList"),
  courseNameList: baseUrl("Course/GetAllCourseNameList"),
  updateUserProfile: baseUrl("User/UpdateUserProfile"),
  getPlatformPreferenceList: baseUrl("Common/GetPlatformList"),
  getCoursesStatusList: baseUrl("Common/GetCourseStatusList"),
  getAllStudentListByCourseNameId: baseUrl(
    "User/GetAllStudentListByCourseNameId"
  ),
  getTutorbyIds: baseUrl("User/GetAllTutorListBySbjIdAndStdsId"),
  courseConversion: baseUrl("Common/GetCourseConversionList"),
  addEditAvailability: baseUrl("Availability/AddEditAvailability"),
  getWeekDays: baseUrl("Common/GetWeekDayList"),
  availabilityDetails: baseUrl("Availability/GetAvailabilityDetailList"),
  deleteAvailableSlots: baseUrl("Availability/DeleteAvailabilityDetailById"),
  getAvailableSlotsById: baseUrl("Availability/GetAvailabilityDetailById"),
  getCourseDetailList: baseUrl("Course/GetCourseDetailedList"),
  getStageList: baseUrl("Course/GetCourseStatusList"),
  getFilterByColumnList: baseUrl("Course/GetFilterByColumnList"),
  lessonDetailList: baseUrl("Course/GetLessonDetailedList"),
  getStudentDetail: baseUrl("Course/GetStudentDetail"),
  addCourseName: baseUrl("CourseName/AddEditCourseName"),
  getCourseNameDetailList: baseUrl("CourseName/GetCourseNameDetailList"),
  editCourseNameDetailList: baseUrl("CourseName/GetCourseNameDetailById"),
  addSubject: baseUrl("Subject/AddEditSubject"),
  getAllSubjectDetailList: baseUrl("Subject/GetAllSubjectDetailList"),
  getSubjectDetailById: baseUrl("Subject/GetSubjectDetailsById"),
  getDashboardDetails: baseUrl("Dashboard/GetDashboardDetails"),
  getProfileChangeDetailsById: baseUrl("Dashboard/GetProfileChangeDetailsById"),
  updateReviewStatus: baseUrl("Dashboard/UpdateReviewStatus"),
  getUserDetail: baseUrl("Course/GetUserDetail"),
  addEditUserAvailability: baseUrl("UserAvailability/AddEditUserAvailability"),
  getAllUserAvailability: baseUrl(
    "UserAvailability/GetAllUserAvailabilityDetailList"
  ),
  deleteUserAvailability: baseUrl(
    "UserAvailability/DeleteUserAvailabilityById"
  ),
  getAvailabilityDetailById: baseUrl(
    "UserAvailability/GetUserAvailabilityDetailById"
  ),
  getAvailabilityCalendar: baseUrl(
    "UserAvailability/GetUserAvailabilityCalendarData"
  ),
  getAllCourseIdList: baseUrl("Scheduling/GetAllCourseIdList"),
  courseFilterListById: baseUrl(
    "Scheduling/GetSchedulingFiltersDataByCourseId"
  ),
  schedulingCalendarData: baseUrl("Scheduling/GetSchedulingCalendarData"),
  getSchedulingPreviewDetail: baseUrl("Scheduling/GetSchedulingPreviewDetail"),
  addScheduling: baseUrl("Scheduling/AddScheduling"),
  getScheduleList: baseUrl("Schedule/GetAllScheduledLessonList"),
  getScheduleTimeSpnaFilterList: baseUrl("Schedule/GetScheduleTimeSpanFilter"),
  getScheduleLessonStatusList: baseUrl("Schedule/GetAllLessonStatus"),
  getTutorActionInfo: baseUrl("Dashboard/GetTutorActionInfo"),
  getcheduledCourseDetailById: baseUrl(
    "Dashboard/GetScheduledCourseDetailsById"
  ),
  changeCourseSchedulingStatus: baseUrl(
    "Dashboard/ChangeCourseSchedulingStatus"
  ),
  getStudentActionInfo: baseUrl("Dashboard/GetStudentActionInfo"),
  getConvertedDateAndTime: baseUrl(
    "UserAvailability/GetConvertedDateAndTimeByOppositeTimeZone"
  ),
  getAllTutorDBDetailList: baseUrl("TutorDB/GetAllTutorDBDetailList"),
  getAllStudentDbDetailList: baseUrl("StudentDB/GetAllStudentDbDetailList"),
  getStudentLiveCourseRate: baseUrl("StudentDB/GetLiveCourseRateListByUserId"),
  getAllUserFullNameList: baseUrl("User/GetAllUserFullNameList"),
  studentAddEditBalance: baseUrl("StudentDB/AddEditBalance"),
  getBalanceDetailList: baseUrl("StudentDB/GetAllBalanceDetailList"),
  getBalanceDetailById: baseUrl("StudentDB/GetBalanceDetailById"),
  addEditStudentNotes: baseUrl("StudentDB/AddEditNotes"),
  addInvoiceNoList: baseUrl("TutorDB/AddInvoiceNoList"),
  tutorDbAddNotes: baseUrl("TutorDB/AddNotes"),
  coursePlan: baseUrl("CoursePlan/AddEditCoursePlan"),
  getCoursePlanById: baseUrl("CoursePlan/GetCoursePlanByCourseId"),
  getLessonListByCourseId: baseUrl("CourseFeedback/GetLessonListByCourseId"),
  getLessonDetailByLessonId: baseUrl(
    "CourseFeedback/GetLessonDetailByLessonId"
  ),
  addEditLessonDetailOfCourseFeedback: baseUrl(
    "CourseFeedback/AddEditCourseFeedback"
  ),
  getCourseFeedbackListByCourseId: baseUrl(
    "CourseFeedback/GetCourseFeedbackListByCourseId"
  ),
  getLessonByInvoiceNo: baseUrl("TutorDB/GetLessonDetailListByInvoiceNo"),

  //Notifications
  baseSignalR: secondaryBaseUrl("hubs/SignalRHandshake"),
  notificationMethodList: baseUrl("Notification/GetNotificationMethodList"),
  notificationPriorityList: baseUrl("Notification/GetPriorityCategoryList"),
  getUserNameListByNotifyFilters: baseUrl(
    "Notification/GetUserNameListByNotifyFilters"
  ),
  addUserNotification: baseUrl("Notification/AddUserNotification"),
  GetRequestTypeList: baseUrl("Notification/GetRequestTypeList"),
  getAllInvoiceList: baseUrl("TutorDB/GetAllInvoiceList"),
  getInvoiceDetailByInvoiceNo: baseUrl("TutorDB/GetInvoiceDetailByInvoiceNo"),
  getAllInvoiceDetailsById: baseUrl("TutorDB/GetAllInvoiceDetailListById"),
  getStudentCurrentBalanceById: baseUrl(
    "StudentDB/GetStudentCurrentBalanceByStudentId"
  ),
  getNextSevenDaysLessonList: baseUrl("Dashboard/GetNextSevenDaysLessonList"),
  getLastFiveDaysLessonList: baseUrl("Dashboard/GetLastFiveDaysLessonList"),
  getDashboardNotificationDetailList: baseUrl(
    "Notification/GetDashboardNotificationDetailList"
  ),
  changeNotificationSeenById: baseUrl(
    "Notification/ChangeNotificationSeenById"
  ),
  getDashboardCoursePlanById: baseUrl("CoursePlan/GetCoursePlanById"),
  dashboardUpdateCoursePlanStatus: baseUrl("Dashboard/UpdateCoursePlanStatus"),
  getLessonStatusList: baseUrl("Dashboard/GetLessonStatusList"),
  updateLessonStatus: baseUrl("Dashboard/UpdateDashboardLessonStatus"),
  getNotifyDetailList: baseUrl("Notification/GetAllNotifyDetailList"),
  getDashboardNotificationList: baseUrl(
    "Notification/GetDashboardNotificationList"
  ),
  chnageNotificationSeenById: baseUrl(
    "Notification/ChangeNotificationSeenById"
  ),
  getStudentProfileDetail: baseUrl("Course/GetStudentProfileDetails"),
  addEditUserUnAvailability: baseUrl(
    "UserAvailability/AddEditUserUnavailability"
  ),
  generalFeedbackReport: baseUrl("Reports/GenerateFeedbackReport"),
  generalBalanceReport: baseUrl("Reports/GenerateBalanceReport"),
  getUserUnAvailabilityCheck: baseUrl(
    "UserAvailability/GetUserUnavailabilityCheck"
  ),
  GetUserProfileBasicInfo: baseUrl("User/GetUserProfileBasicInfo"),
  UpdateUserProfileBasicInfo: baseUrl("User/UpdateUserProfileBasicInfo"),
  generateUniversityReport: baseUrl("Reports/GenerateUniversitySupportReport"),
  generateUniversityReportById: baseUrl(
    "Reports/GetUniverstiyCourseDetailById"
  ),
  downloadUniversityReport: baseUrl("Reports/DownloadUniverstiyTypeReport"),
  studentListByLessonId: baseUrl("Dashboard/GetStudentListByLessonId"),
  userPermissionDetails: baseUrl("Permission/GetPermissionDetails"),
  updatePermission: baseUrl("Permission/UpdatePermission"),
  lessonReminderRequest: baseUrl("Dashboard/LessonRemiderRequest"),
  getTutorMeetingDetails: baseUrl("User/GetTutorMeetingDetails"),
  lessonReminderResponse: baseUrl("Dashboard/LessonRemiderResponse"),
  getRescheduleDetailById: baseUrl("Rescheduling/GetRescheduleDetailsById"),
  generateGeneralReport: baseUrl("Reports/GenerateGeneralReport"),
  getCourseApprovalById: baseUrl("Course/GetCourseApprovalByCourseId"),
  lessonCancelledBySa: baseUrl("Schedule/LessonCancelledBySA"),
  changeCourseStatusById: baseUrl("Course/ChangeCourseStatusById"),
  updateReschedulingTimelineBylesson: baseUrl(
    "Rescheduling/UpdateReschedulingTimeLineByLesson"
  ),
  updateRescheduleLesson: baseUrl("Rescheduling/UpdateRescheduledLesson"),
  StudentsLastWeekLessonHours: baseUrl("Reports/StudentsLastWeekLessonHours"),
  TutorLastWeekLessonHours: baseUrl("Reports/TutorLastWeekLessonHours"),
  LessonHoursEachWeekPerYear: baseUrl("Reports/LessonHoursEachWeekPerYear"),
  updateRescheduleLessonStatus: baseUrl(
    "Rescheduling/UpdateRescheduledLessonStatus"
  ),
  getRescheduleRequestDetailById: baseUrl(
    "Rescheduling/GetRescheduleRequestDetailsByCourseId"
  ),
  updateRescheduleRequestById: baseUrl(
    "Rescheduling/UpdateRescheduleRequestDetailsByCourseId"
  ),
  getSchedulingStudentList: baseUrl("Scheduling/GetSchedulingStudentList"),
  getSchedulingTutorList: baseUrl("Scheduling/GetSchedulingTutorList"),
  getSchedulingCourseListByStudentAndTutor: baseUrl(
    "Scheduling/GetSchedulingCourseListByStudentAndTutor"
  ),
  getTutorMeetingCriteraByLessonId: baseUrl(
    "Dashboard/GetTutorMeetingCriteraByLessonId"
  ),
  getStudentNameByLessonId: baseUrl("TutorDB/GetStudentNamesByLessonId"),
  getLessonStatusListForTutorDb: baseUrl(
    "TutorDB/GetLessonStatusListForTutorDB"
  ),
  getPendingNotificationCount: baseUrl(
    "MobileDashboard/GetPendingNotificationCountByUser"
  ),
  deleteCourseName: baseUrl("CourseName/DeleteCourseNameDetailById"),
  deleteSubjectName: baseUrl("Subject/DeleteSubjectById"),
  getUserProfileDetailsByCourseId: baseUrl(
    "Course/GetUserProfileDetailsByCourseId"
  ),
  getSlotDetailById: baseUrl("UserAvailability/GetSlotDetailsById"),
  saveUpdateSchedulingCriteria: baseUrl(
    "SchedulingV2/SaveUpdateSchedulingCriteria"
  ),
  getCourseCriteriaDetail: baseUrl("SchedulingV2/GetCourseCriteriaDetail"),
  getSummaryDetail: baseUrl("SchedulingV2/GetSummaryDetail"),
  getLessonTimeLineDetail: baseUrl("SchedulingV2/GetLessonTimelineDetail"),
  getSchedulingCalendarDataByCriteria: baseUrl(
    "SchedulingV2/GetSchedulingCalendarDataByCriteria"
  ),
  updateSchedulingCriteria: baseUrl("SchedulingV2/UpdateCriteria"),
  commonLessonCancellation: baseUrl("Common/LessonCancellation"),
  calendarDataByCriteria: baseUrl(
    "SchedulingV2/GetSchedulingInternalCalendarData"
  ),
  updateScheduledLessonFromViewCalendar: baseUrl(
    "SchedulingV2/UpdateScheduledLessonFromViewCalendar"
  ),
  getCoursePlatformDetail: baseUrl("Course/GetPlatformDetailListByTutorId"),
  getFollowUpNotifications: baseUrl("Notification/GetFollowUpNotifications"),
  changeNotificationChaseStatus: baseUrl(
    "Notification/ChangeNotificationChaseStatus"
  ),
  commonUpdateLessons: baseUrl("Common/UpdateLessonStatus"),
  changeNotificationStatusById: baseUrl(
    "Notification/ChangeNotificationStatusById"
  ),
  editUserAvailability: baseUrl(
    "UserAvailability/EditUserAvailabilitySlotById"
  ),
  deleteUserAvailabilityFromRecurring: baseUrl(
    "UserAvailability/DeleteUserAvailabilitySlotById"
  ),
  updateAndSendVerificationEmail: baseUrl(
    "User/UpdateAndSendVerificationEmail"
  ),
  getNotificationStatusList: baseUrl("Notification/GetNotificationStatusList"),
  getUserNameListForFollowUpReport: baseUrl(
    "Notification/GetUserNameListForFollowUpReport"
  ),
  summaryUniversityReport: baseUrl("Reports/GenerateUniversityReport"),
  universityReport: baseUrl("Reports/DownloadUniverstiyReport"),
  getIncompleteLessonDetails: baseUrl("Schedule/GetIncompleteLessonDetails"),
  studentStatementReport: baseUrl("Reports/StudentStatementReport"),
};
